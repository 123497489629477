
.maincont{
    height: 99vh;
    width: 100%;
    display: flex;
    flex-direction: row;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.smenu{
    /* width: 250px; */
    /* border: 1px solid blue; */
    height: 100%;
    transition: ease-in-out;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.header{
    height: 50px;
    /* border: 1px solid saddlebrown; */
    display: flex;
    flex-direction: row;
    padding: 0 25px 0 10px;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.contentcont{
    /* border: 1px solid rebeccapurple; */
    /* width: calc(100vw - 250px); */
    width: 100%;
    background-color: #eaeaea;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.container{
    height: calc(100% - 50px);
    width: 100%;
    background-color: #eaeaea;
    padding: 10px;
    box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 3px 1px -2px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 5px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2) inset;
    transition: ease-in-out;

    /* box-shadow: 0px 10px 5px 0px rgba(207,207,207,0.75) inset;
    -webkit-box-shadow: 0px 10px 5px 0px rgba(207,207,207,0.75) inset;
    -moz-box-shadow: 0px 10px 5px 0px rgba(207,207,207,0.75) inset; */
    /* overflow-x: scroll; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ccont{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    padding: 5px 10px 20px 20px;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.smenu{
    transition: ease-in-out;
}
.smenuhead{
    height: 50px;
    background-color: #0d4c92;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
}

.sideitem:hover{
    background:#ededed;
    border-radius: 5px;
    color: white!important;
    cursor: pointer;
    -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}

.smenucont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    width: 200px;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
}

.smenuconticon{
    padding-top: 10px;
}

.smenucont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
}
.sideitemselected{
    margin-bottom: 10px;
    /* border: 1px solid grey; */
    /* width: 200px; */
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#ededed;
    border-radius: 5px;
    color: white!important;
    cursor: pointer;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}
    
.sideitem{
    margin-bottom: 10px;
    /* border: 1px solid grey; */
    /* width: 200px; */
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}
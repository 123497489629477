.custtablecont{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custtableheader{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.custtablefooter{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custtablefooter > ul{
    margin: 0;    
}

/* .custtablefooter > ul > li{
    display: flex;
} */

.MuiTablePagination-root{
    display: none;
}

.MuiDataGrid-footerContainer{
    display: none!important;
}
.parenthead{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tablecont{
    /* height: calc(100vh-70px); */
    flex: 1;
}
.custcont{
    height: 100%;
    display: flex;
    flex-direction: column;
    /* position: relative; */
}   

.datacont{
    /* height: calc(100vh-30px); */
    /* flex:1; */
    /* height: 610px; */
    /* overflow: scroll; */
    height: 100%;
}

.datahead{
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.datafoot{
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.datafoot > ul {
    display: flex;
    flex-direction: row;
    width: 100px;
    /* overflow:scroll; */
}

.datafoot > ul > li {
    list-style: none;
    
    margin-left: 10px;
}


.custmodal{
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* background: red; */
    z-index: 99;
    /* opacity: 0.1; */
    position: absolute;
    top: 0;
    left: 0;
    

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mcont{
    height: 800px;
    width: 600px;
    background: white;
    border-radius: 15px;
    position: absolute;
    top: calc((100vh - 800px)/2);
    left: calc((100vw - 600px)/2);
    display: flex;
    flex-direction: column;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mheader{
    display: flex;
    justify-content: space-between;
    padding: 15px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mfooter{
    height: 50px;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
}
.mfooter > button{
    /* background-color: #0d4c92; */
    /* color: white; */
    height: 50px;
    /* width: 100px; */
    margin-left: 10px;
    border-radius: 15px;
    font-weight: 700;
}

.mccont{
 
    /* height: 100%; */
    width: 100%;
    padding: 15px;;
    flex: 1;
    /* overflow-y: scroll; */
    /* display: flex; */
    

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.crow{
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
}

.divide{
    margin-top: 15px;
    margin-bottom: 15px;
}

.prodload{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}
.ordermodcont{
    height: 100%;
    display: flex;
    flex-direction: column;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.orderpart{
    flex:2;
    display: grid;
    grid-template-columns: auto auto ;
    padding: 15px 10px 10px 0;


    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.orderpart > div{
    padding-right: 5px;
}

.ordertable{
    flex:3;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}
.pagecont{
    /* width: 300px; */
    /* height: 50px; */
    display: flex;
    /* align-items: center; */
    
}

.pagecont > ul {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 14px;
    
    padding-left: 0;
    
}
.pagecont > ul > li{
    width: 35px;
    display: flex;
    padding: 0;
    
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.pagecont > ul > li > button{
    background: white;
    /* border: 1px solid rgb(192, 192, 192); */
    border: 1px solid transparent;
    /* width: 35px; */
    /* height: 25px; */
    /* margin-left: 3px; */
    padding-top: 3px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
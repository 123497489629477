.logincont{
    height: 100vh;
    width: 100%;
    background-color: #eaeaea;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.headback{
    height: 30vh;
    width: 100%;
    background-color:#0d4c92;
    /* position: absolute; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.contbor{
    height: 500px;
    width: 400px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 3px 1px -2px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 5px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2) ;
    z-index: 999;
    position: absolute;
    top: calc(50vh/2);
    left: calc((100vw - 400px)/2);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.conthead{
    height: 140px;
    width: 100%;
    /* margin-bottom: 50px; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 60px;
    font-weight: bold;
    font-family: "Nunito";
    color: #0d4c92;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
}

.ccontlogin{
    flex: 1;
    height: 100%;
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.uname{
    margin-bottom: 10px!important;
    
}

.pwd{
    margin-bottom: 10px!important;
}

/* .uname{
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
}

.pwd{
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
} */

.submit{
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    background-color: #0d4c92;
    color: white;
    font-weight: bold;
}


/* tag */

.ctag{
    position: absolute;
    z-index: 999;
    bottom: 15px;
    left: calc((100vw - 125px)/2);
}
.orderaddcont{
    height: 100%;
    display: flex;
    flex-direction: column;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.orderAddTitle{
    display: flex;
    flex-direction: row;
     
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


.orderaddpart{
    display: flex;
    flex-direction: row;
    width: 100%;



}
.orderaddpart > div{
    padding-right: 5px;
    display: flex;
    flex-direction: column;

}

.orderaddtable{
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}